@import "/Variables";
/* -------------------------------Screen------------------------------- */
#screenFrame {
    width:100%;
    height:100%;
    z-index: 5;
}
.screen {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    background: $base-color;
    box-shadow: 0 0 10px #000;
    display: flex;
}
.screenImg {
 height:100%;
 width:80%;
 z-index: 1;
}

.screenChoose {
 height:100%;
 width:20%;
box-shadow: 0 0 10px #000;
 z-index: 2;
}
.screenChooseObject {
 height: 25%;
 width:100%;
}

/* -------------------------------Screen-Ende------------------------------- */
// Screenstart
.ScreenStart {
    width: 100%;
    height: 100%;
    background: #5D94FB;
    position: relative;

    .ScreenStartLGSText {
        width: 60%;
        height:auto;
        display:block;
        position: absolute;
        top:40%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .ScreenStartMarioWorld {
        width: 100%;
        height:auto;
        display:block;
        position: absolute;
        bottom:0;

    }
}
