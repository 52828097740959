@import "/Variables";
/* -------------------------------Component------------------------------- */
.Component{
    width: 100%;
    height: 100%;
    background: $base-color;
    position: relative;

    display: flex;
    flex-direction: column;

    color: $font-color;
    text-decoration: none;
    font-size: 1vw;

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        position: absolute;    
        display: flex; 
        justify-content: center; 
        align-items: center;
        transition: 2s;
        opacity:1;
    }
    div{
        position: relative;
        margin:5%;
        text-decoration: none;
        h1{
            font-size: 5em;
            margin:0;
            text-decoration: none;
        }
        h2{
            font-size: 3em;
            margin:0;
        }
        p{
            font-size: 1em;
            margin:0;
        }
    }
}



// Style Box
.BoxV1{
    width: 120px;
    height: 120px;
    margin: 20px;
    margin-right: 0;
    box-shadow: 0 0 10px #000;

    float: left;
    .BoxListImg{
        width: 100%;
        height: 100%;
    }

    font-size: 0.2vw;
  }

  .BoxV2{
    width: 120px;
    height: 120px;
    margin: 20px;
    margin-right: 0;
    box-shadow: 0 0 10px #000;
    float: left;
    .BoxListImg{
        width: 100%;
        height: 100%;
    }

    font-size: 0.2vw;

    border: 10px solid $base-color;
  }
  .BoxV3{
    width: 120px;
    height: 120px;
    margin: 20px;
    margin-right: 0;
    clip-path: circle(50% at 50% 50%);
    float: left;
    .BoxListImg{
        width: 100%;
        height: 100%;
    }

    font-size: 0.2vw;

    border: 10px solid $base-color;
  }

//Style Rechteck

.RechteckV1{
    margin-left:auto;
    margin-right: auto;

    width:80%;
    height: auto;
    margin-top: 5%;
    box-shadow: 0 0 10px #000;
    flex-direction: row;
 
    font-size: 0.5vw;
    a {
        display: flex;
    }

    div{
        margin:2%;
    }
    img{
        width:25%;
        position: relative;

    }
    h1{
        margin:0;
        
    }
    h2{
        margin:0;
        
    }

}
//Banner Liste
.BannerListe{
    display: flex;
    flex-direction: column;
    div {
        width:auto;
    }
}
// Grid
.Grid{
    width:100%;
    height:100%;
    display: Grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(10, 1fr);
}

.Grid01{
    grid-column: 1 / 5;
    grid-row: 1 / 4;
    background: #555;
}
.Grid02{
    grid-column: 5 / 8;
    grid-row: 1 / 3;
    background: #777;
}
.Grid03{
    grid-column: 8 / 11;
    grid-row: 1 / 4;
    background: #aaa;
}
.Grid04{
    grid-column: 1 / 3;
    grid-row: 4 / 8;
    background: #bbb;
}
.Grid05{
    grid-column: 3 / 5;
    grid-row: 4 / 7;
    background: #999;
}
.Grid06{
    grid-column: 5 / 8;
    grid-row: 3 / 7;
    background: #666;
}
.Grid07{
    grid-column: 8 / 11;
    grid-row: 4 / 8;
    background: #888;
}
.Grid08{
    grid-column: 1 / 3;
    grid-row: 8 / 11;
    background: #aaa;
}
.Grid09{
    grid-column: 3 / 8;
    grid-row: 7 / 11;
    background: #777;
}
.Grid10{
    grid-column: 8 / 11;
    grid-row: 8 / 11;
    background: #ccc;
}

/* -------------------------------Component-Ende------------------------------- */