@import "/Variables";
/* -------------------------------PostBlock------------------------------- */
.PostBlock {
    width: 100%;
    height: 600px;
    background: #999;
    position: relative;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
            
        display: flex; 
        justify-content: center; 
        align-items: center;
        transition: 2s;
        opacity:1;
    }
    .PostBlockText{
        margin:0;
        position: absolute;
        left: 10%;
        width:60%; 
    }

    h1{
        font-size: 5em;
    }
    p{

        line-height: 1.5em;
        font-size: 1.5em;
    }
}

/* -------------------------------PostBlock-Ende------------------------------- */
