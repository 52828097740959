@import "/Variables";

.imgCenter img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
        
    display: flex; 
    justify-content: center; 
    align-items: center;
    transition: 2s;
    opacity:1;
}
.imgCenter img:hover {
    transition: 0.5s;
    opacity:0.7;
}

.Shadow {
    box-shadow: 0 0 10px #000;
}

.Balken{
    width:100%;
    height:20px;
    background: $base-color;
}

