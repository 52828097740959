@import "/Variables";
/* -------------------------------Navbar------------------------------- */
.navbarFrame{
    z-index: 20;
    position: relative;
}

.navbar {
    padding: 0;
    margin: 0;
    width: 100%;
    height: auto;
    background: $base-color;
    box-shadow: 0 0 10px #000;
    display: flex;
    z-index: 10;
}
.navbarDiv {
    width:auto;
    height:100px;
    margin-left: 1em;
    transition: 0.5s;
}
.navbarDiv:hover {
    background: #999;
    transition: 0.5s;
}


.navbar img {
    height:100px;
}

/* menü öffner */
.navbarMenue {
    margin:0;
    padding: 0;
    width:auto;
    height:100px;
    list-style: none;
    float: right;
    position: fixed;
    right: 0;
}
/* !!! Bei hover das img ändern auf jeweiliges farbige symbol */

/* -------------------------------Navbar-Ende------------------------------- */
