@import "/Variables";
/* -------------------------------Footer------------------------------- */
.footerFrame{
    width: 100%;
    height:400px;
    position: relative;
    z-index: 20;
    padding: 0;
    margin: 0;

    p {
        padding: 0;
        margin: 0;  
        font-size: 3em;
        text-decoration: none;
        color: #000;
    }

    .footerleft {
        width: 25%;
        height: 100%;
        padding: 5%;
    }


}


/* -------------------------------Footer-Ende------------------------------- */