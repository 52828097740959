@import "/Variables";

.Ho {
    width: 100%;
    height:100%;
    background: $base-color;
}

.Horow {
    display: flex;
}

.Hocol {

}

