//Basis Style
@import "styles/navbar";
@import "styles/Footer";
//Componenten Styles
@import "styles/Component";
@import "styles/screen";
@import "styles/socialmedia";


@import "styles/PostBlock";
//Allgemeine Styles
@import "styles/lib";
@import "styles/variables";
@import "styles/template";
//APP.js Rahmen
body{
    width:100%;
    padding: 0;
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
    overflow-x: hidden;
}
.AppGrid{
    width:100%;
    position: relative;
    z-index: 10;
    display: grid;
    grid-template-columns: repeat(12, 1fr);

    //Standard-Grid
    grid-template-rows: repeat(12, 200px);
    .AppGrid01 {
        grid-column: 1 / 13;
        grid-row: 1;
        z-index: 0;
    }
    .AppGrid02 {
        grid-column: 1 / 13;
        grid-row: 2;
        z-index: 0;
    }
    .AppGrid03 {
        grid-column: 1 / 13;
        grid-row: 3;
        z-index: 0;
    }
    .AppGrid04 {
        grid-column: 1 / 13;
        grid-row: 4;
        z-index: 0;
    }
    .AppGrid05 {
        grid-column: 1 / 13;
        grid-row: 5;
        z-index: 0;
    }
    .AppGrid06 {
        grid-column: 1 / 13;
        grid-row: 6;
        z-index: 0;
    }
    .AppGrid07 {
        grid-column: 1 / 13;
        grid-row: 7;
        z-index: 0;
    }
    .AppGrid08 {
        grid-column: 1 / 13;
        grid-row: 8;
        z-index: 0;
    }
    .AppGrid09 {
        grid-column: 1 / 13;
        grid-row: 9;
        z-index: 0;
    }
    .AppGrid10 {
        grid-column: 1 / 13;
        grid-row: 10;
        z-index: 0;
    }

}




