@import "/Variables";
/* -------------------------------Social-Media------------------------------- */
.socialMediaFrame{
    width: 100%;
    height: 100%;
}
    

.socialMediaList{
    height:100%;
    img{
        height: 25%;
    }
}

.socialMediaListObject{
    height:25%;
}
/* -------------------------------Social-Media-Ende------------------------------- */
